import React from 'react';
import { Text } from 'rebass';
import { titleList } from './css/positions.css';
import CareerPosition from '../../../components/layout/Career/CareerPosition';
import { ListItemSquare } from '../../../components/layout/ListItemComponents/ListItemSquare';

const WorkerOpCenterSk: React.FC = () => {
  return (
    <CareerPosition positionTitle="Radnik u operativnom centru (slovački jezik)">
      <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
        Znanja i veštine:
      </Text>
      <ListItemSquare text={'Dobre komunikacione veštine'} />
      <ListItemSquare text={'Znanje slovačkog jezika'} />
      <ListItemSquare text={'Osnovno poznavanje auto-industrije'} />
      <ListItemSquare text={'Poznavanje rada na računaru'} />
      <ListItemSquare text={'Poznavanje programa za obradu teksta, rada u tabelama i korišćenja interneta'} />
      <ListItemSquare text={'Poznavanje rada u programima za odnose sa korisnicima'} />
      <ListItemSquare text={'Tačnost u pridržavanju rokova'} />
      <ListItemSquare text={'Neosuđivanost za krivična dela'} />
      <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
        Opis Poslova
      </Text>
      <ListItemSquare text={'Pruža podršku krajnjim korisnicima telefonskim putem'} />
      <ListItemSquare
        text={'Povezuje partnere i krajnje korisnike radi rešavanja pitanja koja nije moguće rešiti putem aplikativnog softvera poslodavca'}
      />
    </CareerPosition>
  );
};

export default WorkerOpCenterSk;
